<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <Table
          ref="table"
          :groups="groups"
          :data_loaded="data_loaded"
          @data_loaded="data_loaded = true"
          @update_data="update_table()"
        ></Table>

        <v-btn
          elevation="8"
          class="mt-5"
          v-if="!form"
          @click="form = !form"
          fab
          small
        >
          <v-icon>add</v-icon>
        </v-btn>
        <Form
          :places="places"
          :groups="groups"
          @update_table="update_table()"
          @close_form="form = !form"
          v-if="form"
        ></Form>
      </div>
    </div>
  </v-container>
</template>

<script>
import Table from "@/components/employees/table";
import Form from "@/components/employees/form";
export default {
  name: "employees_page",
  data() {
    return {
      form: false,
      data_loaded: false,
      groups: [],
    };
  },
  components: {
    Table,
    Form,
  },
  methods: {
    get_groups() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "groups",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.groups = Object.assign([], res.data.data);
        });
    },
    update_table() {
      this.$refs.table.get_data();
    },
  },
  mounted() {
    this.get_groups();
  },
};
</script>
