<template>
  <v-card class="mt-10">
    <v-card-title>עובד חדש</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row class="basic mt-2">
          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              outlined
              dense
              :rules="[(v) => !!v || 'חובה']"
              v-model="employee.name"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                שם
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              outlined
              dense
              @keydown="phone_number_exist = false"
              v-model="employee.phone_number"
              type="number"
              :rules="[(v) => !!v || 'חובה', !phone_number_exist || 'כבר קיים']"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                מספר טלפון
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              outlined
              dense
              v-model="employee.id_number"
              type="number"
              label="ת.ז"
              @keydown="id_number_exist = false"
              :rules="[!id_number_exist || 'כבר קיים']"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              outlined
              dense
              v-model="employee.country"
              label="עיר"
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-select
              background-color="white"
              outlined
              dense
              item-text="name"
              item-value="id"
              :items="groups"
              v-model="employee.group_id"
              label="קבוצה"
            ></v-select>
          </v-col>

          <v-col cols="6" md="4">
            <v-text-field
              v-model="employee.password"
              background-color="white"
              :rules="[(v) => !!v || 'חובה']"
              outlined
              dense
              type="password"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                סיסמה
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              readonly
              outlined
              dense
              label="סטטווס"
              :value="employee.status ? 'פעיל' : 'לא פעיל'"
            >
              <template slot="append">
                <v-switch
                  v-model="employee.status"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          :disabled="save_loader"
          :loading="save_loader"
          x-large
          depressed
          height="40"
          @click="save()"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Form",
  props: ["groups"],
  data() {
    return {
      phone_number_exist: false,
      id_number_exist: false,
      save_loader: false,
      employee: {
        name: "",
        phone_number: null,
        id_number: null,
        country: null,
        status: true,
        password: "",
        group_id: null,
      },
    };
  },
  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
      }
      this.$emit("close_form");
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `employees`,
              method: "post",
            },
            data: this.employee,
          })
          .then((res) => {
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            let errors = err.response.data.data;
            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this.$set(this, `${e}_exist`, true);
                }
              });
            });
          });
      }
    },
  },
};
</script>
